import TagManager from 'react-gtm-module';

export const gtmHandleSearchClick = (testVersionName: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'eventGA',
      eventCategory: 'AB test',
      eventAction: 'clic sur moteur',
      testVersionName,
    },
  });
};

export const gtmHandleServiceClick = (
  eventLabel: string,
  testVersionName: string,
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'eventGA',
      eventCategory: 'AB test',
      eventAction: 'clic sur mosaique',
      eventLabel,
      testVersionName,
    },
  });
};
