export const promiseEntries = {
  imageUrl: '/images/components/Promise/xavier-white.jpg',
  title: 'AD S’engage',
  text1: 'Prendre soin avec vous',
  text2: 'de votre véhicule',
  detail: `En quelques clics, trouvez votre prestation, obtenez un devis en ligne et prenez
          rendez-vous dans l’un de nos 2000 garages multimarques partout en France.`,
};

export const stepsEntries = [
  { label: 'Je choisis ma prestation', icon: 'etape-1' },
  { label: 'J’obtiens un devis immédiat', icon: 'etape-2' },
  { label: 'Je prends rendez-vous', icon: 'etape-3' },
];
