import type { FunctionComponent } from 'react';
import React, { useEffect, useState } from 'react';

import styles from './StickyComponent.module.scss';

import { mergeClassNames } from 'src/utils/ReactUtils';

type StickyComponentProps = {
  offset?: number;
  className?: string;
  hideOnFooter?: boolean;
  alwaysVisible?: boolean;
};

const StickyComponent: FunctionComponent<StickyComponentProps> = ({
  children,
  className,
  offset = 0,
  hideOnFooter = false,
  alwaysVisible = false,
}) => {
  const [isVisible, setIsVisible] = useState(alwaysVisible);

  useEffect(() => {
    let scrolling = false;

    const setScrolling = () => {
      scrolling = true;
    };

    document.addEventListener('scroll', setScrolling);

    const intervalId = setInterval(() => {
      if (scrolling) {
        const footerHeight = 350;
        if (
          hideOnFooter &&
          scrollY + window.innerHeight + footerHeight >
            document.body.offsetHeight
        ) {
          setIsVisible(false);
          return;
        }

        if (alwaysVisible) {
          setIsVisible(true);
        } else if (
          window.pageYOffset > offset &&
          window.innerHeight + window.pageYOffset < document.body.offsetHeight
        ) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    }, 300);

    return () => {
      document.removeEventListener('scroll', setScrolling);
      clearInterval(intervalId);
    };
  }, [offset, hideOnFooter, alwaysVisible]);

  return (
    <div
      className={mergeClassNames([
        styles.stickyComponent,
        !isVisible && styles.stickyComponentHidden,
        className,
      ])}
      data-testid="sticky_component"
    >
      {children}
    </div>
  );
};

export default StickyComponent;
