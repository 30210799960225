// import { useRouter } from 'next/router';
import type { FunctionComponent } from 'react';
import React from 'react';
import TagManager from 'react-gtm-module';

import { useQuotationEngineContext } from 'src/components/layouts/PublicLayout/components/QuotationEngine/QuotationEngineContext';
import { ButtonPrimary, Title3 } from 'src/components/shared';
import StickyComponent from 'src/components/shared/StickyComponent';

import type { Garage } from '../../../../models';
import styles from './StickyCta.module.scss';

import { usePageType } from 'src/utils/ReactUtils';

export type StickyCtaProps = {
  categoryId?: number;
  landingPageId?: number;
  redirectURL?: string;
  garage?: Garage;
};

const StickyCta: FunctionComponent<StickyCtaProps> = ({
  categoryId,
  landingPageId,
  redirectURL,
  garage,
}) => {
  const { openQuotationEngine } = useQuotationEngineContext();
  // const router = useRouter();
  const pageType = usePageType();

  const gtmEvent = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: 'moteur recherche de prestation sticky',
        eventAction: 'clic recherche de prestation',
        eventLabel: '/',
        pageType,
      },
    });
  };

  return (
    <StickyComponent hideOnFooter offset={50} className={styles.stickyCta}>
      <Title3 className={styles.stickyCtaTitle}>
        <h3>
          Obtenez un devis &<br /> Un rendez-vous immédiat
        </h3>
      </Title3>
      <ButtonPrimary
        onClick={() => {
          gtmEvent();
          if (redirectURL) {
            window.location.href = redirectURL;

            // router.push({
            //   pathname: redirectURL,
            // });
          } else {
            openQuotationEngine({ categoryId, landingPageId, garage });
          }
        }}
        className={styles.stickyCtaButton}
        leftIcon="recherche"
        rightIcon="fleche-d"
        iconHeight={24}
        iconWidth={24}
      >
        Rechercher une prestation
      </ButtonPrimary>
    </StickyComponent>
  );
};

export default StickyCta;
