import TagManager from 'react-gtm-module';

export const trackSetServiceSearchQuery = (
  serviceSearchQuery: string,
  pageType?: string,
) => {
  if (serviceSearchQuery.length >= 4) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: 'pop-in recherche de prestation',
        eventAction: 'mot-cle saisi',
        eventLabel: serviceSearchQuery,
        pageType,
      },
    });
  }
};

export const trackSelectServiceAfterSetServiceSearchQuery = (
  serviceSearchQuery: string,
  serviceName: string,
  pageType: string,
) => {
  if (serviceSearchQuery.length >= 4) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: 'pop-in recherche de prestation',
        eventAction: 'clic suggestions de prestation',
        eventLabel: serviceName,
        pageType,
      },
    });
  }
};

export const trackSelectTopCategory = (
  categoryName: string,
  position: number,
  pageType: string,
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'eventGA',
      eventCategory: 'pop-in recherche de prestation',
      eventAction: 'clic prestations courantes',
      eventLabel: categoryName,
      position,
      pageType,
    },
  });
};

export const trackSetCategoriesModeAfterSetServiceSearchQuery = (
  serviceSearchQuery: string,
  categoriesMode: 'top' | 'all',
) => {
  if (serviceSearchQuery.length >= 4 && categoriesMode === 'all') {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: 'pop-in recherche de prestation',
        eventAction: 'clic suggestions de prestation',
        eventLabel: 'voir toutes les prestations',
      },
    });
  }
};

export const trackClickGoToServicesPage = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'eventGA',
      eventCategory: 'pop-in recherche de prestation',
      eventAction: 'clic picto prestation',
      eventLabel: 'voir toutes les prestations',
    },
  });
};

export const trackClickGoToServicesPageFromAutocomplete =
  (pageType: string) => () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: 'pop-in recherche de prestation',
        eventAction: 'clic suggestions de prestation',
        eventLabel: 'voir toutes les prestations',
        pageType,
      },
    });
  };

export const trackFinalSubmit = (
  serviceName: string,
  vehicleName: string,
  zipCode: string,
  pageType: string,
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'eventGA',
      eventCategory: 'pop-in recherche de prestation',
      eventAction: 'recherche prestation',
      eventLabel: serviceName,
      postalCode: zipCode,
      vehicle: vehicleName,
      pageType,
    },
  });
};

export const trackQuotationEngineRendering = (pageName: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'virtualPageview',
      virtualPagePath: `${pageName}/pop-in/moteur-del`,
      virtualPageTitle: 'Moteur DEL',
    },
  });
};

export const trackSearchByModel = (pageType: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'eventGA',
      eventCategory: 'pop-in recherche de prestation',
      eventAction: 'recherche par modele',
      eventLabel: '/',
      pageType,
    },
  });
};

export const trackLocateMe = (pageType: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'eventGA',
      eventCategory: 'pop-in recherche de prestation',
      eventAction: 'clic geolocalisation',
      eventLabel: '/',
      pageType,
    },
  });
};

export const trackClickOnSuggestions = (
  eventLabel: string,
  position: number,
  pageType: string,
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'eventGA',
      eventCategory: 'pop-in recherche de prestation',
      eventAction: 'clic prestation proposee',
      eventLabel,
      position,
      pageType,
    },
  });
};

export const trackClickOnSearchButton = (pageType: string) => () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'eventGA',
      eventCategory: 'moteur recherche de prestation sticky',
      eventAction: 'clic recherche de prestation',
      eventLabel: '/',
      pageType,
    },
  });
};
