import { useRouter } from 'next/router';
import type { FunctionComponent } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import type { Garage, ServiceFamilyModel } from 'src/models';

import { useQuotationEngineContext } from 'src/components/layouts/PublicLayout/components/QuotationEngine/QuotationEngineContext';
import {
  trackClickOnSearchButton,
  trackSelectTopCategory,
} from 'src/components/layouts/PublicLayout/components/QuotationEngine/utils/dataLayer';
import { Icon, InlineCritizr, Link } from 'src/components/shared';
import type { IconType } from 'src/components/shared/Icon/types';
import type { InlineCritizrProps } from 'src/components/shared/InlineCritizr/InlineCritizr';
import Responsive from 'src/components/shared/Responsive/Responsive';
import Form from 'src/components/ui/Form';
import InputGroup from 'src/components/ui/InputGroup';

import {
  gtmHandleSearchClick,
  gtmHandleServiceClick,
} from '../../trackingUtils';
import style from './SearchEngine.module.scss';

import { mergeClassNames, usePageType } from 'src/utils/ReactUtils';

type Step = {
  label: string;
  icon: string;
};

export type SearchEngineProps = {
  countServices: number;
  servicesFamily: ServiceFamilyModel[];
  steps: Step[];
  title: string;
  allowMobileMode?: boolean;
  landingPageId?: number;
  categoryId?: number;
  couponCode?: string;
  placeholder?: string;
  isSticky?: boolean;
  redirectURL?: string;
  garage?: Garage;
} & InlineCritizrProps;

const singleServiceWith = 105;
const widthToSubtract = 50;

const SearchEngine: FunctionComponent<SearchEngineProps> = ({
  averageRating,
  countServices,
  servicesFamily,
  steps,
  title,
  totalRatings,
  className,
  allowMobileMode = false,
  landingPageId,
  categoryId = undefined,
  couponCode,
  placeholder,
  isSticky = false,
  redirectURL,
  garage,
}) => {
  const { openQuotationEngine } = useQuotationEngineContext();
  const router = useRouter();
  const pageType = usePageType();
  const [services, setServices] = useState<ServiceFamilyModel[]>([]);
  const servicesRef = useRef<HTMLDivElement>(null);

  const gtmTrackingHandler = (dataLayer: unknown) => {
    TagManager.dataLayer({
      dataLayer: dataLayer as never,
    });
  };

  const handleResize = useCallback(() => {
    setTimeout(() => {
      const ServicesWrapperDiv = servicesRef?.current;
      if (ServicesWrapperDiv) {
        const getDisplayedServicesNumber = (): number => {
          const finalServicesWrapperDiv =
            ServicesWrapperDiv.offsetWidth - widthToSubtract;
          return Math.round(finalServicesWrapperDiv / singleServiceWith);
        };

        if (servicesFamily?.length) {
          const newServicesEntries: ServiceFamilyModel[] =
            servicesFamily?.slice(0, getDisplayedServicesNumber());
          setServices(newServicesEntries);
        }
      }
    });
  }, [servicesFamily]);

  useEffect(() => {
    const isLandingPage = router.asPath.startsWith('/promotion/');
    if (isLandingPage) return;

    gtmTrackingHandler({
      event: 'virtualPageview',
      virtualPagePath: isLandingPage ? router.asPath : '/',
      virtualPageTitle: pageType === 'homepage' ? 'Home' : pageType,
    });
  }, [router, pageType]);

  const gtmClickHandler = () => {
    gtmHandleSearchClick('Version initiale moteur');
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, servicesFamily]);

  const renderServices: JSX.Element[] = services?.map(
    (service: ServiceFamilyModel, index) => {
      return (
        <div
          key={index}
          className={style.searchLink}
          data-code-service={service.idFamilleIdg}
          onClick={() => {
            gtmHandleServiceClick(service.title, 'Version initiale moteur');
            if (service.redirectUrl) {
              window.location.href = service.redirectUrl;
              // router.push({
              //   pathname: service.redirectUrl,
              // });
              return;
            }
            openQuotationEngine({
              garage,
              landingPageId,
              categoryId: service.idFamilleIdg,
              couponCode,
            });
          }}
          data-testid="engine_prestation_item"
        >
          <div
            className={mergeClassNames([style.iconLabel, style.searchItem])}
            onClick={() => {
              trackSelectTopCategory(service.title, service.order, pageType);
            }}
          >
            <Icon
              name={service.icon as IconType}
              className={style.searchItemIcon}
              width={32}
              height={32}
            />
            <span className={style.searchItemTitle}>
              {service.title.toLowerCase()}
            </span>
          </div>
        </div>
      );
    },
  );

  const renderSteps: JSX.Element[] = steps.map((step, index) => {
    return (
      <div
        key={index}
        className={mergeClassNames([style.iconLabel, style.searchStepsItem])}
      >
        <Icon
          color={'#4A0B0F'}
          height={46}
          width={46}
          name={step.icon as never}
        />
        <span className={style.searchStepLabel}> {step.label} </span>
      </div>
    );
  });

  const renderCritizr = () => (
    <div
      onClick={() =>
        gtmTrackingHandler({
          event: 'eventGA',
          eventCategory: 'moteur recherche de prestation',
          eventAction: 'clic avis clients',
          eventLabel: '/',
        })
      }
      className={style.searchFeedback}
    >
      <InlineCritizr
        averageRating={averageRating}
        totalRatings={totalRatings}
        className={style.searchCritizr}
      />
    </div>
  );

  return (
    <div data-testid="engine_container">
      <div className={mergeClassNames([style.search, className])}>
        <div
          data-testid={`search_engine_wrapper${isSticky ? '_sticky' : ''}`}
          data-coupon={couponCode}
          className={style.searchWrapper}
          onClick={() => {
            gtmClickHandler();
            if (redirectURL) {
              window.location.href = redirectURL;
              // router.push({
              //   pathname: redirectURL,
              // });
            } else {
              openQuotationEngine({
                garage,
                landingPageId,
                categoryId,
                couponCode,
              });
            }
          }}
        >
          {isSticky ? (
            <p className={style.searchTitle} data-testid="home_engine_title">
              {title}
            </p>
          ) : (
            <h2 className={style.searchTitle} data-testid="home_engine_title">
              {title}
            </h2>
          )}

          {!allowMobileMode ? (
            renderCritizr()
          ) : (
            <Responsive is="desktop" cloneChild>
              {renderCritizr()}
            </Responsive>
          )}
        </div>

        <InputGroup
          className={style.searchInputGroup}
          onClick={() => {
            gtmClickHandler();
            if (redirectURL) {
              window.location.href = redirectURL;
              // router.push({
              //   pathname: redirectURL,
              // });
            } else {
              openQuotationEngine({
                garage,
                landingPageId,
                categoryId,
                couponCode,
              });
            }
          }}
        >
          <Form.Control
            type="text"
            name="SearchEngineServiceInput"
            id="SearchEngineServiceInput"
            readOnly
            className={style.searchInputGroupText}
            placeholder={placeholder || `${countServices} Services disponibles`}
            aria-label="ad Services"
            aria-describedby="ad Services"
            data-testid="home_engine_input"
          />
          <button
            onClick={trackClickOnSearchButton(pageType)}
            className={style.searchInputGroupButton}
            data-testid="home_engine_button"
          >
            <Icon
              name="recherche"
              className={`d-none d-md-block ${style.searchInputGroupIcon}`}
              height={28}
              width={28}
            />
            <span className={style.searchInputLabel}>Rechercher</span>
            <Icon
              name="fleche-d"
              className={`d-none d-md-block ${style.searchInputGroupIcon}`}
              height={28}
              width={28}
            />
          </button>
        </InputGroup>
        {servicesFamily.length > 0 && (
          <div ref={servicesRef} className={style.searchServices}>
            {renderServices}

            <Link
              href="/prestation"
              legacy
              className={mergeClassNames([style.iconLabel, style.moreLink])}
              onClick={() =>
                gtmTrackingHandler({
                  event: 'eventGA',
                  eventCategory: 'moteur recherche de prestation',
                  eventAction: 'clic picto prestation',
                  eventLabel: 'voir toutes les prestations',
                  pageType,
                })
              }
              data-testid="engine_prestation_plus"
            >
              <Icon
                name="plus-moteur"
                className={style.iconPlus}
                width={32}
                height={32}
              />
            </Link>
          </div>
        )}
        {allowMobileMode && (
          <Responsive is="mobile" cloneChild>
            {renderCritizr()}
          </Responsive>
        )}
        {!landingPageId && (
          <div
            className={`d-none d-md-flex ${style.searchSteps} ${style.searchStepsHome}`}
          >
            {renderSteps}
          </div>
        )}
      </div>
      {landingPageId && (
        <div className={`d-none d-md-flex ${style.searchSteps}`}>
          {renderSteps}
        </div>
      )}
    </div>
  );
};

export default SearchEngine;
