import Head from 'next/head';
import type { FunctionComponent } from 'react';
import React from 'react';

import { renderHTML } from 'src/utils/ReactUtils';

type MetaRichSnippetProps = {
  content: unknown;
};

const MetaRichSnippet: FunctionComponent<MetaRichSnippetProps> = ({
  content,
}) => {
  if (!content) return <></>;

  if (typeof content === 'string') {
    return renderHTML(content as string);
  }

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(content) }}
      />
    </Head>
  );
};

export default MetaRichSnippet;
